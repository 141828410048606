import instance from '../indexDeviceManager';

// Util
import { isMultiParent } from 'util/multidevice';
const BE_VERSION = 'backend-version';
const DEVICE_BROKER = 'device-broker'
const CHANGE = 'change';
const CONFIGURATION = 'configuration';
const DEVICE_MANAGER = 'deviceManager';
const DEVICE = 'device';
const GETBYPLANT = 'findByPlant';
const QUERY = 'query';
const SETTINGS = 'settings'
const UPDATE_SCHEDULING = 'update-scheduling';

const DeviceManagerApi = {
  /**
  * Api to add a new device 
   */
  addNewDevice({ payload }) {
    return instance.post(`/${DEVICE}`, payload);
  },

  /**
   * Endpoint used to change the device configuration
   * @param {Object} deviceData - ex {id: 3, model: "151P", plant: "1",  "mac_address": "CC50E3ABDAB4",
   * "configured": true}
   * @param {Object} config - ex {STANDBY: 1}
   */
  changeDeviceConfiguration({ deviceData, config }) {
    deviceData.id = isMultiParent(deviceData.id) ? deviceData.id.slice(1) : deviceData.id
    const payload = {
      ...deviceData,
      configuration: config,
    };

    return instance.post(`/${DEVICE_MANAGER}/${CONFIGURATION}/${CHANGE}`, payload);
  },
  /**
   * Endpoint used to get all device
   */
  getAlldevice(plantId) {
    return instance.get(`/${DEVICE}/${GETBYPLANT}/${plantId}`);
  },

  /**
   * Api to get the device broker data 
   */
  getDeviceBroker() {
    return instance.get(`${SETTINGS}/${DEVICE_BROKER}`)
  },
  /**
   * Endpoint used to retrieve the device data
   * @param {Number} deviceId
   * @returns device data
   */
  getDeviceDataById(deviceId) {
    deviceId = isMultiParent(deviceId) ? parseInt(deviceId.slice(1)) : deviceId
    return instance.get(`/${DEVICE}/${deviceId}`);
  },

  getKeyByFamilyGroup({ payload }) {
    return instance.post(`/${DEVICE_MANAGER}/${CONFIGURATION}/${QUERY}`, payload);
  },

  /**
* Api to get the last Supported Version of app from the be (only for mobile app)
* @returns 
*/
  getLastSupportedVersion() {
    return instance.get(`${SETTINGS}/${BE_VERSION}`);
  },
  /**
   * Api to remove device
  */
  removeDeviceById(deviceId) {
    deviceId = isMultiParent(deviceId) ? parseInt(deviceId.slice(1)) : deviceId
    return instance.delete(`/${DEVICE}/${deviceId}`,);
  },
  /**
   * APi to remove an array of device
   */
  removeDevices({ deviceIds }) {
    return instance.delete(`/${DEVICE}/`, { deviceIds });
  },

  /**
   * Api to update device 
    */
  updateDeviceById(deviceId, payload) {
    deviceId = isMultiParent(deviceId) ? parseInt(deviceId.slice(1)) : deviceId
    return instance.put(`/${DEVICE}/${deviceId}`, payload);
  },
  /**
  * Endpoint used to update the alarm of device
  * @param {Object} deviceId 
  * @param {Object} payload 
  */
  updateScheduledCalendar({ deviceId, payload }) {
    deviceId = isMultiParent(deviceId) ? parseInt(deviceId.slice(1)) : deviceId
    return instance.put(`/${DEVICE}/${deviceId}/${UPDATE_SCHEDULING}`, payload);
  },
};

export default DeviceManagerApi;
