import { useCallback, useEffect, useState } from 'react';

// Api
import DeviceManagerApi from 'api/modules/deviceManager';

// Util
import { Capacitor } from '@capacitor/core';

/**
 *  Hook used to get lastSupportedVersion version from be and handle the modal
 * @returns 
 */
export const useAppVersion = () => {
    const appVersion = process.env.REACT_APP_VERSION;
    const [openModal, setOpenModal] = useState(false);

    /*
    * method used to fetch last Supported Version for app from be
    */
    const fetchData = useCallback(async () => {
        const { data, statusCode } = await DeviceManagerApi.getLastSupportedVersion();
        if (statusCode == 200) {
            parseFloat(data.lastSupportedVersion) > parseFloat(appVersion) ? setOpenModal(true) : setOpenModal(false)
        } else {
            return
        }
    }, [])

    useEffect(() => {
        fetchData();
    }, [])

    /**
     * method used to handle the modal and go to store
     */
    const handleUpdateApp = () => {
        const platform = Capacitor.getPlatform();
        if (platform == 'ios') {
            window.location.href = 'itms-apps://itunes.apple.com/app/id6446782142'
        } else {
            window.location.href = 'market://details?id=com.ionic2_1.app"'
        }
    }

    return { openModal, handleUpdateApp }
}