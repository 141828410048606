import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

// Styles
import styles from './BaseModal.module.scss';
import BaseIcon from '../BaseIcon/BaseIcon';

const BaseModal = ({ children, customClass, isOpen, closeFn, showCloseIcon }) => {
  const backdropRef = useRef(null);
  const nodeRef = useRef(null);

  /**
   * Method used to close the modal on outside click
   */
  const closeModal = (e) => {
    if (
      typeof e.target.className === 'string' &&
      e.target.className.includes('modal__backdrop') &&
      closeFn
    ) {
      closeFn();
    }
  };

  return createPortal(
    <>
      <CSSTransition
        in={isOpen}
        nodeRef={backdropRef}
        timeout={500}
        classNames={{
          enter: styles.modal__backdropEnter,
          enterActive: styles.modal__backdropEnterActive,
          exit: styles.modal__backdropExit,
          exitActive: styles.modal__backdropExitActive,
        }}
        unmountOnExit
      >
        <div ref={backdropRef} className={styles.modal__backdrop} onClick={closeModal}></div>
      </CSSTransition>
      <CSSTransition
        in={isOpen}
        nodeRef={nodeRef}
        timeout={300}
        classNames={{
          enter: styles.modal__enter,
          enterActive: styles.modal__enterActive,
          exit: styles.modal__exit,
          exitActive: styles.modal__exitActive,
        }}
        unmountOnExit
      >
        <div ref={nodeRef} className={[styles.modal__content, customClass].join(' ')}>
          {showCloseIcon && (
            <BaseIcon icon='cross' onClick={closeFn} customClass={styles.modal__close} />
          )}
          {children}
        </div>
      </CSSTransition>
    </>,
    document.getElementById('modal'),
  );
};

BaseModal.propTypes = {
  children: PropTypes.any,
  customClass: PropTypes.string,
  isOpen: PropTypes.bool,
  closeFn: PropTypes.func,
  showCloseIcon: PropTypes.bool,
};

export default BaseModal;
