import { createAsyncThunk, } from '@reduxjs/toolkit'

// Api
import UserApi from 'api/modules/user'

// Cognito 
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'

// Data
import Pool from 'data/UserPool';

// Store
import { LoginActions } from './login-slice';

export const user = Pool.getCurrentUser();

export const getSession = async () => {
    return await new Promise((resolve, reject) => {
        if (user) user.getSession((err, session) => {
            if (err) reject()
            else resolve(session)
        })
        else reject()
    })
}

export const logout = () => {
    if (user) user.signOut()
    localStorage.removeItem('cognitoToken'); // deletes token from storage
    localStorage.removeItem('token') // deletes token from storage
    window.location.href = '/'
}


// Login after confirm
export const authenticate = createAsyncThunk(
    // action type string
    'user/login',
    // callback function

    async (user, { dispatch }) => {
        const { email, password } = user;
        const cognitoUser = new CognitoUser({ Username: email, Pool });
        const authenticationDetails = new AuthenticationDetails({ Username: email, Password: password })
        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: async authdata => {
                    const cognitoToken = authdata.getAccessToken().getJwtToken();
                    localStorage.setItem('cognitoToken', cognitoToken);

                    const { statusCode, data } = await UserApi.getAuthToken({ token: { rawToken: cognitoToken } });
                    if (statusCode === 200) {
                        localStorage.setItem('token', data.accessToken)
                        window.location.href = '/'
                    } else {
                        throw new Error('Internal Server Error');
                    }
                    resolve()
                },
                onFailure: async err => {
                    dispatch(LoginActions.setErrorMsg(err.message))
                    localStorage.setItem('error', err.message);
                    reject(err)
                },
                newPasswordRequired: authdata => {
                    console.log('newPasswordRequired:', authdata);
                    return
                }
            })

        })
    }
)

// Confirm sign up and activate user
export const confirmSignUp = (infos) => {
    return async (dispatch) => {
        const { email, otp, authProviderId, token } = infos;
        const { statusCode, msg } = await UserApi.activateUser({ payload: { email: email, confimationCode: otp, authProviderId: authProviderId, invitationToken: token } });
        if (statusCode === 200) {
            dispatch(LoginActions.increaseStep())
        } else {
            dispatch(LoginActions.setErrorMsg(msg))
        }
    }
};

// Resend Confirmation Code
export const resendConfirmationCode = (email) => {
    return async (dispatch) => {
        getUser(email).resendConfirmationCode(err => {
            if (err) {
                dispatch(LoginActions.setErrorMsg(err.message));
            } else {
                dispatch(LoginActions.setSuccessCodeSent(true));
            }
        })
    }
}

// SendCode for Reset Password
export const sendCode = async (e) => {
    getUser(e).forgotPassword({
        onSuccess: data => { console.log('onSucess:', data) },
        onFailure: err => {
            return err
        },
        inputVerificaionCode: data => { console.log('Input:', data); }
    })
}

// Reset Password
export const resetPassword = (item) => {
    const { resetData } = item;
    const { email, code, password } = resetData;
    const dispatch = item.dispatch;
    getUser(email, dispatch).confirmPassword(code, password, {
        onSuccess: (data) => {
            console.log('onSuccess:', data);
            dispatch(LoginActions.increaseStep());
        },
        onFailure: async (err) => {
            const errorMsg = err.message || 'Unknown error';
            dispatch && dispatch(LoginActions.setErrorMsg(errorMsg));
        },
    });
};

// get Cognito User
export const getUser = (email) => {
    return new CognitoUser({
        Username: email.toLowerCase(),
        Pool
    })
}

// check user forgotPassword status
export const checkforgotPswStatus = (user) => {
    return async (dispatch) => {
        const { data, statusCode, msg } = await UserApi.checkCognitoStatus({ email: user.email });
        if (statusCode === 200) {
            if (data.isExist && data.isConfirmed) {
                sendCode(user.email);
                dispatch(LoginActions.increaseStep());
            } else if (data.isExist) {
                dispatch(LoginActions.setErrorIntoStatus('login.unconfirmed_user'));
            } else {
                dispatch(LoginActions.setErrorIntoStatus('login.email_not_exist'));
            }
        } else {
            dispatch(LoginActions.setErrorIntoStatus(msg));
        }
    }
}
// check user confirm status on Login
export const checkLoginStatus = (user) => {
    return async (dispatch) => {
        const { data, statusCode, msg } = await UserApi.checkCognitoStatus({ email: user.email });
        if (statusCode === 200) {
            if (data.isExist && data.isConfirmed) {
                dispatch(authenticate(user))
            } else if (data.isExist) {
                dispatch(LoginActions.setUserAndUnconfirmed({
                    user: { email: data.email, auth_provider_id: data.username },
                    confirmed: true,
                }))
            } else {
                const err = 'error_email_message'
                dispatch(LoginActions.setErrorMsg(err))
            }
        } else {
            dispatch(LoginActions.setErrorMsg(msg))
        }
    }
}

// check user confirm status on Sign up
export const checkSignUpStatus = createAsyncThunk(
    'user/checkSignUpStatus',
    async (user, { dispatch }) => {
        const { data, statusCode, msg } = await UserApi.checkCognitoStatus({ email: user.email });
        if (statusCode === 200) {
            if (data.isExist && data.isConfirmed) {

                dispatch(LoginActions.setError(true));
                dispatch(LoginActions.setErrorMsg('login.email_already_used'));

            } else if (data.isExist) {
                dispatch(LoginActions.setUserAndUnconfirmed({
                    user: { email: data.email, auth_provider_id: data.username },
                    confirmed: true,
                }))
                dispatch(LoginActions.increaseStep());
            } else {
                dispatch(registerUser({ user: user }));
            }
        } else {
            dispatch(LoginActions.setErrorMsg(msg))
        }
    }
)

// check user confirm status on Sign up when is invited
export const CheckInviationSignUpStatus = (email, step) => {
    return async (dispatch) => {
        const { data, statusCode } = await UserApi.checkCognitoStatus({ email: email });
        if (statusCode === 200) {
            if (!data.isExist && !data.isConfirmed) return;
            if (!data.isConfirmed) {
                dispatch(LoginActions.setStepAndConfirmUser({
                    step: step,
                    unconfirmed: true,
                    email: data.email,
                    auth_provider_id: data.username,
                    invitationToken: data.invitationToken
                }))
            }
        }
    }
}


// Registration 
export const registerUser = createAsyncThunk(
    // action type string
    'user/register',
    // callback function
    async (infos, { dispatch }) => {

        const { user, isNewOrg } = infos;
        // registring user to BE
        const { data, statusCode, msg } = await UserApi.signUp({ payload: user });
        if (statusCode === 200) {
            dispatch(LoginActions.setUserAndUnconfirmed({
                user: data.user,
                confirmed: true,
            }));
            dispatch(LoginActions.increaseStep());
        } else if (isNewOrg && statusCode !== 200) dispatch(LoginActions.setShowModal(true));
        else if (statusCode !== 200 && statusCode != 400) {
            return { statusCode: statusCode, data: data, errorMsg: msg };
        }
        else if (statusCode > 400) {
            return {
                statusCode: statusCode,
                data: data,
                errorMsg: msg
            };
        }
    }
)



