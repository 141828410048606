import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import BaseIcon from '../BaseIcon/BaseIcon';

// Styles
import styles from './BaseButton.module.scss';

/**
 * Component used to display a button.
 * @prop {Function} onClick
 * @prop {Any} children
 * @prop {String} customClass
 * @prop {String} to
 * @prop {String} href
 * @prop {String} type
 * @prop {Boolean} disabled
 * @prop {String} buttonType
 * @prop {Boolean} isLoading - it sets loading state of the button. It doesn't work for text buttons
 */
const BaseButton = ({
  onClick,
  children,
  customClass,
  to,
  href,
  type,
  disabled,
  buttonType,
  isLoading,
}) => {
  const buttonClasses = [styles.button, customClass];
  if (disabled) {
    buttonClasses.push(styles.button_disabled);
  }

  if (isLoading) {
    buttonClasses.push(styles.button_loading);
  }

  switch (buttonType) {
    case 'line':
      buttonClasses.push(styles.button_line);
      break;
    case 'text':
      buttonClasses.push(styles.button_text);
      break;
    case 'danger':
      buttonClasses.push(styles.button_danger);
      break;
  }

  if (href) {
    return (
      <a className={buttonClasses.join(' ')} href={href}>
        {children}
      </a>
    );
  }
  if (to) {
    return (
      <Link to={to} className={buttonClasses.join(' ')}>
        {!isLoading ? (
          children
        ) : (
          <BaseIcon icon='loading' customClass={styles.button__iconLoading} />
        )}
      </Link>
    );
  }

  if (buttonType === 'text') {
    <p className={buttonClasses.join(' ')} onClick={onClick}>
      {children}
    </p>;
  }

  return (
    <button className={buttonClasses.join(' ')} onClick={onClick} type={type} disabled={disabled}>
      {!isLoading ? children : <BaseIcon icon='loading' customClass={styles.button__iconLoading} />}
    </button>
  );
};

BaseButton.propTypes = {
  children: PropTypes.any,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  buttonType: PropTypes.oneOf(['line', 'text', 'danger']),
  isLoading: PropTypes.bool,
};

export default BaseButton;
