import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
    name: 'notifications',
    initialState: {
        alertVisible: true,
        commonVisible: false,
        invitationVisible: false,
        notificationsList: [],
        readedVisible: false,
        deviceToken: ''
    },
    reducers: {
        setNotifications(state, action) {
            state.notificationsList = action.payload;
        },
        addNotification(state, action) {
            state.notificationsList.push(action.payload);
        },
        setAlertVisible(state, action) {
            state.alertVisible = action.payload
        },
        setDeviceToken(state, action) {
            state.deviceToken = action.payload;
        },

        setInvitationVisible(state, action) {
            state.invitationVisible = action.payload
        },
        setReadedVisible(state, action) {
            state.readedVisible = action.payload
        },
        setCommonVisible(state, action) {
            state.commonVisible = action.payload
        }
    }
})
export const notificationActions = notificationSlice.actions;

export default notificationSlice;

