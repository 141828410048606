import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Translations
import { useTranslation } from 'react-i18next';

// Components
import NavItem from '../NavItem/NavItem';

// Styles
import styles from './Navigation.module.scss';

/**
 * Component used to display bottom navigation
 */
const Navigation = () => {
  const { t } = useTranslation();
  const currentPlantUser = useSelector((state) => state.plants.currentPlantUser);
  const { platform } = useSelector((state) => state.app);

  const [isOrganizationsEmpty, setIsOrganizationsEmpty] = useState(false);

  useEffect(() => {
    setIsOrganizationsEmpty(currentPlantUser?.organizations?.length === 0);
  }, [currentPlantUser]);

  // Define the navigation routes
  let navRoutes = [
    { title: t('navigation.products'), url: '/products', icon: 'products' },
    { title: t('navigation.calendar'), url: '/calendar', icon: 'calendar' },
    { title: t('navigation.plants'), url: '/plants', icon: 'drop' },
    { title: t('navigation.people'), url: '/people', icon: 'people' },
    { title: t('navigation.assistance'), url: '/assistance', icon: 'support' },
  ];

  // If platform is not 'web', remove the first two elements and the last one
  if (platform !== 'web') {
    navRoutes = navRoutes.slice(2, -1);
  }

  // Helper function to determine if an item should be rendered
  const shouldRenderItem = (index) => {
    if (platform === 'web' && isOrganizationsEmpty && index === navRoutes.length - 2) {
      // Skip second-to-last item for web if organizations are empty
      return false;
    }
    if (platform !== 'web' && isOrganizationsEmpty && index === navRoutes.length - 1) {
      // Skip last item for non-web if organizations are empty
      return false;
    }
    return true;
  };

  return (
    <div className={styles.navigation}>
      <ul className={styles.navigation__list}>
        {navRoutes.map((item, index) => {
          if (!shouldRenderItem(index)) return null;
          return <NavItem key={item.title} {...item} />;
        })}
      </ul>
    </div>
  );
};

export default Navigation;