import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Component used to render the correct image based on the screen width
 * @prop {String} img - phone image
 * @prop {String} img2x - tab image
 * @prop {String} img3x - big image for desktop screens
 * @prop {String} alt
 * @prop {String} className
 * @prop {Number} desktopWidth - image width (in px) on desktop screen > 1200px
 * @prop {Number} tabWidth- image width (in px) on tab screen 600px - 1200px
 * @prop {Number} phoneWidth - image width (in px) on mobile screen < 600px>
 */

const BaseImage = ({
  alt,
  className,
  customStyle,
  desktopWidth,
  img,
  img2x,
  img3x,
  onClick,
  phoneWidth,
  tabWidth,
}) => {
  const [image, setImage] = useState(null);

  const [image2x, setImage2x] = useState(null);
  const [image3x, setImage3x] = useState(null);

  useEffect(() => {
    if (img && img2x && img3x) {
      // Get the images width (to pass it in srcSet)
      const imgSmall = new Image();
      imgSmall.src = img;
      imgSmall.onload = () => {
        setImage(imgSmall);
      };

      const imgMedium = new Image();
      imgMedium.src = img2x;
      imgMedium.onload = () => {
        setImage2x(imgMedium);
      };

      const imgBig = new Image();
      imgBig.src = img3x;
      imgBig.onload = () => {
        setImage3x(imgBig);
      };
      return () => {
        setImage(null);
        setImage2x(null);
        setImage3x(null);
      };
    }
  }, [img, img2x, img3x]);

  const desktopBreakpoint = 75;
  const tabBreakpoint = 56.25;
  const phoneBreakpoint = 37.5;

  /**
   * Calculate the % of the view width that the images takes base on the breakpoint
   * @param {Number} width - image width in px
   * @param {Number} breakpoint - breakpoint in rems
   * @returns
   */
  const calculateVwWidth = (width, breakpoint) => {
    const widthInVw = (width / (breakpoint * 16)) * 100;
    return Math.round(widthInVw * 10) / 10;
  };

  const desktopWidthInVw = calculateVwWidth(desktopWidth, desktopBreakpoint);
  const tabWidthInVw = calculateVwWidth(tabWidth, tabBreakpoint);
  const phoneWidthInVw = calculateVwWidth(phoneWidth, phoneBreakpoint);

  if (!image || !image2x || !image3x) {
    return (
      <img src={img3x || img2x || img} alt={alt || ''} className={className} style={customStyle} />
    );
  }

  return (
    <img
      srcSet={`${img} ${image.width}w, ${img2x} ${image2x.width}w, ${img3x} ${image3x.width}w`}
      sizes={`(max-width: ${desktopBreakpoint}em) ${desktopWidthInVw}vw, (max-width: ${tabBreakpoint}em) ${tabWidthInVw}vw, (max-width: ${phoneBreakpoint}em) ${phoneWidthInVw}vw, 100vw`}
      alt={alt || ''}
      style={customStyle}
      className={className}
      src={img3x || img2x || img}
      onClick={onClick}
    />
  );
};

BaseImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  customStyle: PropTypes.object,
  desktopWidth: PropTypes.number,
  img: PropTypes.string.isRequired,
  img2x: PropTypes.string,
  img3x: PropTypes.string,
  onClick: PropTypes.func,
  phoneWidth: PropTypes.number,
  tabWidth: PropTypes.number,
};

export default BaseImage;
