import { createSlice } from '@reduxjs/toolkit';
import { checkIsSense } from 'components/devices/IwSense/utils/senseDevices';

const initialState = {
    devices: {},
    parametersArray: [],
};

const parametersSlice = createSlice({
    name: 'parameters',
    initialState,
    reducers: {
        setInitialState: (state) => {
            state.parametersArray = [];
            state.devices = {}
        },
        setId: (state, action) => {
            const { deviceId } = action.payload;

            if (!state.devices[deviceId]) {
                state.devices[deviceId] = { id: deviceId };
            }
            state.devices[deviceId].id = deviceId;
        },
        setError: (state, action) => {
            const { deviceId, payload } = action.payload;

            if (!state.devices[deviceId]) {
                state.devices[deviceId] = { id: deviceId };
            }
            state.devices[deviceId].error = payload;
        },
        resetState: () => {
            return initialState;
        },
        setDevice: (state, action) => {
            const { deviceId, device } = action.payload;
            if (Object.keys(device).length === 0) {
                state.devices = {};
            } else {
                state.devices[deviceId] = { ...device };
            }
        },

        setParametersArray: (state, action) => {
            const { newParam, isParamTrend } = action.payload;

            // If the new parameters array is empty, reset parametersArray
            if (Array.isArray(newParam) && newParam.length === 0) {
                state.parametersArray = newParam;
                return;
            }

            // Check if the device is a "sense"
            const isSenseDevice = checkIsSense(newParam.device.model);

            let newArray;

            // If the device is a "sense", update the elements with the same "channel" and "measure"
            if (isSenseDevice) {
                newArray = state.parametersArray.map(param => {
                    if (param.device.id === newParam.device.id && param.channel === newParam.channel && param.measure === newParam.measure) {
                        return newParam; // Update the existing parameter
                    }
                    return param;
                });

                // Check if the element already exists, otherwise add it
                if (!newArray.some(param => param.device.id === newParam.device.id && param.channel === newParam.channel && param.measure === newParam.measure)) {
                    newArray.push(newParam);
                }
            } else {
                // If not a "sense", keep the original behavior
                newArray = state.parametersArray.map(param => {
                    if (param.device.id === newParam.device.id && param.title === newParam.title) {
                        return newParam;
                    }
                    return param;
                });

                // Special condition for non-sense devices
                if (!(newParam.device.position === 2 && (newParam.title === 'Temperature' || newParam.title === 'Flow Rate') && !isParamTrend)) {
                    if (!newArray.some(param => param.device.id === newParam.device.id && param.title === newParam.title)) {
                        newArray.push(newParam);
                    }
                }
            }

            // Sort array by device ID
            newArray.sort((a, b) => a.device.id - b.device.id);

            // Update state
            return { ...state, parametersArray: newArray };
        },
    }
});

export const {
    setParameter,
    setId,
    setError,
    resetState,
    setDevice,
    setParametersArray,
    setInitialState
} = parametersSlice.actions;

export default parametersSlice;
