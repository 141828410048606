import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeIndices: [],
  alarmData: [],
  alarmId: null,
  calendarInput: null,
  confirmModal: false,
  days: [],
  editCalendar: false,
  endTime: '',
  hours: [],
  isLoading: false,
  open: false,
  openDeleteModal: false,
  startTime: '',
  messageError: null
}
const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    resetState() {
      return { ...initialState };
    },
    setAlarmData(state, action) {
      state.alarmData = action.payload;
    },
    setActiveIndices(state, action) {
      state.activeIndices = action.payload;
    },
    setAlarmId(state, action) {
      state.alarmId = action.payload;
    },
    setCalendarInput(state, action) {
      state.calendarInput = action.payload;
    },
    setConfirmModal(state, action) {
      state.confirmModal = action.payload;
    },
    setDays(state, action) {
      const sortedDays = action.payload.slice().sort((a, b) => b.val - a.val);
      state.days = sortedDays;
    },

    setEndTime(state, action) {
      state.endTime = action.payload;
    },
    setHours(state, action) {
      state.hours = action.payload;
    },
    setStartTime(state, action) {
      state.startTime = action.payload;
    },
    setMessageError(state, action) {
      state.messageError = action.payload;
    },
    openModal: (state, action) => {
      state.open = true;
      state.editCalendar = true;
      state.alarmId = action.payload;
    },
    setOpen(state, action) {
      state.open = action.payload;
    },
    setOpenDeleteModal(state, action) {
      state.openDeleteModal = action.payload;
    },
    setEditCalendar(state, action) {
      state.editCalendar = action.payload;
    },
    setIsLoading(state, action) {
      state.editCalendar = action.payload;
    },
    setTimes(state, action) {
      state.startTime = action.payload.start;
      state.endTime = action.payload.end;
    },
    toggleModals: (state, action) => {
      state.open = action.payload.open;
      state.openDeleteModal = action.payload.openDeleteModal;
    },
    cancelAlarm: (state, action) => {
      state.openDeleteModal = false;
      state.confirmModal = true;
      state.activeIndices = action.payload.initialState;
    },
    resetModal(state) {
      state.open = false;
      state.startTime = null;
      state.endTime = null;
    },
    setModalAndTimes(state, action) {
      state.open = action.payload.open;
      state.startTime = action.payload.start;
      state.endTime = action.payload.end;
    },
  }
});

export const CalendarActions = calendarSlice.actions;

export default calendarSlice;